module.exports = [{
      plugin: require('D:/Projects/andrewmkane.com/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"C:\\Projects\\andrewmkane.com","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('D:/Projects/andrewmkane.com/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-182L8VN2MN"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('D:/Projects/andrewmkane.com/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('D:/Projects/andrewmkane.com/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
